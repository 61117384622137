import { ClientCreateState, ClientDetailsState, ClientListState, ClientUpdateState } from '../models/client.model'
import { createFeature, createReducer, on } from '@ngrx/store'
import { clientCreateActions, clientDetailsActions, clientListActions, clientUpdateActions } from './actions'
import { profileDetailsActions, profileListActions } from '../../profiles/store/actions'
import { ProfileDetailsState, ProfilesState } from '../../profiles/models/profiles.model'

const initialClientCreateState: ClientCreateState = {
    clientCreateIsLoading: false,
    clientCreateError: '',
    client: null,
}

const initialClientUpdateState: ClientUpdateState = {
    updateClientIsLoading: false,
    updateClientError: '',
    updateClientSuccess: false,
}

const initialClientsState: ClientListState = {
    clientListIsLoading: false,
    clients: [],
}

const initialClientDetailsState: ClientDetailsState = {
    clientDetailsIsLoading: false,
    clientDetailsError: '',
    client: null,
}

const clientsListFeature = createFeature({
    name: 'clientsList',
    reducer: createReducer(
        initialClientsState,
        on(clientListActions.requestClientList, (state) => ({ ...state, clientListIsLoading: true })),
        on(clientListActions.requestClientListSuccess, (state, event) => ({
            ...state,
            clientListIsLoading: false,
            clients: event.clients,
        })),
    ),
})

const clientCreateFeature = createFeature({
    name: 'clientCreate',
    reducer: createReducer(
        initialClientCreateState,
        on(clientCreateActions.requestClientCreate, (state: ClientCreateState) => ({
            ...state,
            clientCreateIsLoading: true,
        })),
        on(clientCreateActions.requestClientCreateSuccess, (state: ClientCreateState, event) => ({
            ...state,
            clientCreateIsLoading: false,
            client: event.client,
        })),
        on(clientCreateActions.requestClientCreateFailed, (state: ClientCreateState, event) => ({
            ...state,
            clientCreateIsLoading: false,
            clientCreateError: event.error,
        })),
        on(clientCreateActions.resetClientCreateState, (state: ClientCreateState, event) => initialClientCreateState),
    ),
})

const clientUpdateFeature = createFeature({
    name: 'clientUpdate',
    reducer: createReducer(
        initialClientUpdateState,
        on(clientUpdateActions.requestClientUpdate, (state: ClientUpdateState) => ({
            ...state,
            updateClientIsLoading: true,
        })),
        on(clientUpdateActions.requestClientUpdateSuccess, (state: ClientUpdateState, event) => ({
            ...state,
            updateClientIsLoading: false,
            updateClientSuccess: true,
        })),
        on(clientUpdateActions.requestClientUpdateFailed, (state: ClientUpdateState, event) => ({
            ...state,
            updateClientIsLoading: false,
            updateClientError: event.error,
        })),
        on(clientUpdateActions.resetClientUpdateState, (state: ClientUpdateState, event) => initialClientUpdateState),
    ),
})

const clientDetailsFeature = createFeature({
    name: 'clientDetails',
    reducer: createReducer(
        initialClientDetailsState,
        on(clientDetailsActions.requestClientById, (state: ClientDetailsState) => ({
            ...state,
            clientDetailsIsLoading: true,
        })),
        on(clientDetailsActions.requestClientByIdSuccess, (state: ClientDetailsState, event) => ({
            ...state,
            clientDetailsIsLoading: false,
            clientDetailsError: '',
            client: event.client,
        })),
        on(clientDetailsActions.requestClientByIdFailed, (state: ClientDetailsState, event) => ({
            ...state,
            clientDetailsIsLoading: false,
            clientDetailsError: '',
            client: null,
        })),
        on(clientDetailsActions.resetClientByIdState, (state: ClientDetailsState, event) => initialClientDetailsState),
    ),
})

export const {
    name: clientsListFeatureKey,
    reducer: clientListReducer,
    selectClientListIsLoading,
    selectClients,
} = clientsListFeature

export const {
    name: clientsCreateFeatureKey,
    reducer: clientCreateReducer,
    selectClientCreateIsLoading,
    selectClientCreateError,
    selectClient,
} = clientCreateFeature

export const {
    name: clientUpdateFeatureKey,
    reducer: clientUpdateReducer,
    selectUpdateClientIsLoading,
    selectUpdateClientError,
    selectUpdateClientSuccess,
} = clientUpdateFeature

export const {
    name: clientDetailsFeatureKey,
    reducer: clientDetailsReducer,
    selectClientDetailsIsLoading,
    selectClientDetailsError,
    selectClient: selectClientDetails,
} = clientDetailsFeature
