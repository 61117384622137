import { createFeature, createReducer, on } from '@ngrx/store'
import { siteCreateActions, siteDetailsActions, siteListActions, siteUpdateActions } from './actions'
import { SiteCreateState, SiteDetailsState, SiteListState, SiteUpdateState } from '../models/sites.models'

const initialSiteCreateState: SiteCreateState = {
    siteCreateIsLoading: false,
    siteCreateError: '',
    site: null,
}

const initialSiteUpdateState: SiteUpdateState = {
    updateSiteIsLoading: false,
    updateSiteError: '',
    updateSiteSuccess: false,
}

const initialSitesState: SiteListState = {
    siteListIsLoading: false,
    siteListError: '',
    sites: [],
}

const initialSiteDetailsState: SiteDetailsState = {
    siteDetailsIsLoading: false,
    siteDetailsError: '',
    site: null,
}

const sitesListFeature = createFeature({
    name: 'sitesList',
    reducer: createReducer(
        initialSitesState,
        on(siteListActions.requestSiteList, (state) => ({ ...state, siteListIsLoading: true })),
        on(siteListActions.requestSiteListSuccess, (state, event) => ({
            ...state,
            siteListIsLoading: false,
            sites: event.sites,
        })),
        on(siteListActions.requestSiteListFailed, (state, event) => ({
            ...state,
            siteListIsLoading: false,
            siteListError: event.error,
        })),
    ),
})

const siteCreateFeature = createFeature({
    name: 'siteCreate',
    reducer: createReducer(
        initialSiteCreateState,
        on(siteCreateActions.requestSiteCreate, (state: SiteCreateState) => ({
            ...state,
            siteCreateIsLoading: true,
        })),
        on(siteCreateActions.requestSiteCreateSuccess, (state: SiteCreateState, event) => ({
            ...state,
            siteCreateIsLoading: false,
            site: event.site,
        })),
        on(siteCreateActions.requestSiteCreateFailed, (state: SiteCreateState, event) => ({
            ...state,
            siteCreateIsLoading: false,
            siteCreateError: event.error,
        })),
        on(siteCreateActions.resetSiteCreateState, (state: SiteCreateState, event) => initialSiteCreateState),
    ),
})

const siteUpdateFeature = createFeature({
    name: 'siteUpdate',
    reducer: createReducer(
        initialSiteUpdateState,
        on(siteUpdateActions.requestSiteUpdate, (state: SiteUpdateState) => ({
            ...state,
            updateSiteIsLoading: true,
        })),
        on(siteUpdateActions.requestSiteUpdateSuccess, (state: SiteUpdateState, event) => ({
            ...state,
            updateSiteIsLoading: false,
            updateSiteSuccess: true,
        })),
        on(siteUpdateActions.requestSiteUpdateFailed, (state: SiteUpdateState, event) => ({
            ...state,
            updateSiteIsLoading: false,
            updateSiteError: event.error,
        })),
        on(siteUpdateActions.resetSiteUpdateState, (state: SiteUpdateState, event) => initialSiteUpdateState),
    ),
})

const siteDetailsFeature = createFeature({
    name: 'siteDetails',
    reducer: createReducer(
        initialSiteDetailsState,
        on(siteDetailsActions.requestSiteById, (state: SiteDetailsState) => ({
            ...state,
            siteDetailsIsLoading: true,
        })),
        on(siteDetailsActions.requestSiteByIdSuccess, (state: SiteDetailsState, event) => ({
            ...state,
            siteDetailsIsLoading: false,
            siteDetailsError: '',
            site: event.site,
        })),
        on(siteDetailsActions.requestSiteByIdFailed, (state: SiteDetailsState, event) => ({
            ...state,
            siteDetailsIsLoading: false,
            siteDetailsError: '',
            site: null,
        })),
        on(siteDetailsActions.resetSiteByIdState, (state: SiteDetailsState, event) => initialSiteDetailsState),
    ),
})

export const {
    name: sitesListFeatureKey,
    reducer: siteListReducer,
    selectSiteListIsLoading,
    selectSiteListError,
    selectSites,
} = sitesListFeature

export const {
    name: sitesCreateFeatureKey,
    reducer: siteCreateReducer,
    selectSiteCreateIsLoading,
    selectSiteCreateError,
    selectSite,
} = siteCreateFeature

export const {
    name: siteUpdateFeatureKey,
    reducer: siteUpdateReducer,
    selectUpdateSiteIsLoading,
    selectUpdateSiteError,
    selectUpdateSiteSuccess,
} = siteUpdateFeature

export const {
    name: siteDetailsFeatureKey,
    reducer: siteDetailsReducer,
    selectSiteDetailsIsLoading,
    selectSiteDetailsError,
    selectSite: selectSiteDetails,
} = siteDetailsFeature
