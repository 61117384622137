import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Client, ClientDto, ClientUpdateDto } from '../models/client.model'
import { Profile } from '../../profiles/models/profiles.model'

export const clientListActions = createActionGroup({
    source: 'clientList',
    events: {
        'Request Client List': emptyProps(),
        'Request Client List Success': props<{ clients: Client[] }>(),
        'Request Client List Failed': (error: any) => ({ error }),
    },
})

export const clientCreateActions = createActionGroup({
    source: 'clientCreate',
    events: {
        'Request Client Create': props<{ dto: ClientDto }>(),
        'Request Client Create Success': props<{ client: Client }>(),
        'Request Client Create Failed': (error: any) => ({ error }),
        'Reset Client Create State': emptyProps(),
    },
})

export const clientUpdateActions = createActionGroup({
    source: 'clientUpdate',
    events: {
        'Request Client Update': props<{ dto: ClientUpdateDto }>(),
        'Request Client Update Success': props<{ success: boolean }>(),
        'Request Client Update Failed': (error: any) => ({ error }),
        'Reset Client Update State': emptyProps(),
    },
})

export const clientDetailsActions = createActionGroup({
    source: 'clientDetails',
    events: {
        'Request Client By Id': props<{ id: number }>(),
        'Request Client By Id Success': props<{ client: Client }>(),
        'Request Client By Id Failed': (error: any) => ({ error }),
        'Reset Client By Id State': emptyProps(),
    },
})
