import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Profile } from '../../profiles/models/profiles.model'
import { Site, SiteCreateDto, SiteUpdateDto } from '../models/sites.models'

export const siteListActions = createActionGroup({
    source: 'siteList',
    events: {
        'Request Site List': props<{ clientId: number }>(),
        'Request Site List Success': props<{ sites: Site[] }>(),
        'Request Site List Failed': (error: any) => ({ error }),
    },
})

export const siteCreateActions = createActionGroup({
    source: 'siteCreate',
    events: {
        'Request Site Create': props<{ dto: SiteCreateDto }>(),
        'Request Site Create Success': props<{ site: Site }>(),
        'Request Site Create Failed': (error: any) => ({ error }),
        'Reset Site Create State': emptyProps(),
    },
})

export const siteUpdateActions = createActionGroup({
    source: 'siteUpdate',
    events: {
        'Request Site Update': props<{ dto: SiteUpdateDto }>(),
        'Request Site Update Success': props<{ success: boolean }>(),
        'Request Site Update Failed': (error: any) => ({ error }),
        'Reset Site Update State': emptyProps(),
    },
})

export const siteDetailsActions = createActionGroup({
    source: 'siteDetails',
    events: {
        'Request Site By Id': props<{ id: number }>(),
        'Request Site By Id Success': props<{ site: Site }>(),
        'Request Site By Id Failed': (error: any) => ({ error }),
        'Reset Site By Id State': emptyProps(),
    },
})
