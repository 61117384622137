import { Injectable } from '@angular/core'
import {
    PostgrestMaybeSingleResponse,
    PostgrestResponse,
    PostgrestSingleResponse,
    SupabaseClient,
} from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Observable, Subscriber } from 'rxjs'
import { Client, ClientDto, ClientUpdateDto } from '../models/client.model'

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    private supabase!: SupabaseClient

    constructor(private supabaseService: SupabaseService) {
        this.supabase = this.supabaseService.getClient()
    }

    clientDetails(id: number): Observable<Client> {
        return new Observable((subscriber: Subscriber<Client>) => {
            this.supabase
                .from('clients')
                .select('*')
                .eq('id', id)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Client>) => subscriber.next(result.data!))
        })
    }

    clientList(): Observable<Client[]> {
        return new Observable((subscriber: Subscriber<Client[]>) => {
            this.supabase
                .from('clients')
                .select('*')
                .then((result: PostgrestResponse<Client>) => subscriber.next(result.data!))
        })
    }

    createClient(client: ClientDto): Observable<Client> {
        return new Observable((subscriber: Subscriber<Client>) => {
            try {
                this.supabase
                    .from('clients')
                    .insert(client)
                    .select()
                    .then((result: PostgrestResponse<Client>) => {
                        if (result.error != null) {
                            subscriber.error(result.error.message)
                        }
                        if (result.data?.length) {
                            subscriber.next(result.data![0])
                        }
                    })
            } catch (e) {
                return subscriber.error('Could not create client')
            }
        })
    }

    updateClient(client: ClientUpdateDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            try {
                const { id, ...clientData } = client

                console.warn(client)

                const query = this.supabase.from('clients').update(clientData).eq('id', id)

                query.then((result: PostgrestSingleResponse<null>) => {
                    if (result.error != null) {
                        subscriber.error(result.error.message)
                    }
                    if (result.status === 204) {
                        subscriber.next(true)
                    }
                })
            } catch (e) {
                console.warn('the error')
                console.warn(e)
                return subscriber.error('Could not update client')
            }
        })
    }
}
